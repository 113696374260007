import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  AggregatorTemplate,
  Typography,
  PinBlockGroup,
  AggregatorPin4,
  TransitionLink,
  ReadMore,
} from '../../components'
import { pipe, map, path, pathOr, take, filter } from 'ramda'
import styles from './CatalysingPageTemplate.module.css'

const propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        intro: PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
        }),
      }),
    }),
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
    projects: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
              excerpt: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }),
}

const getProjectsByCategory = categoryTitle => {
  return pipe(
    map(a => path(['node'], a)),
    filter(({ frontmatter }) => {
      return pathOr([], ['categories'], frontmatter).includes(categoryTitle)
    }),
    take(4),
  )
}

export const CatalysingPageTemplate = ({ data }) => {
  const {
    page,
    categories: { edges: categories },
    projects: { edges: projects },
  } = data
  const {
    frontmatter: { intro },
  } = page

  return (
    <MDXProvider>
      <AggregatorTemplate breadcrumbs={[{ label: 'Catalysing' }]} intro={intro}>
        <Divider thickness={4} />
        {categories.map((c, i) => {
          const { slug } = c.node.fields
          const { title } = c.node.frontmatter
          const categorisedProjects = getProjectsByCategory(title)(projects)

          return (
            categorisedProjects.length > 0 && (
              <div key={i}>
                <Typography tag="h2" type="boxHeading">
                  {title}
                </Typography>
                <PinBlockGroup columns={2}>
                  {categorisedProjects.map(({ frontmatter, fields }, i) => (
                    <AggregatorPin4
                      key={i}
                      title={frontmatter.title}
                      thumbnailSrc={frontmatter.featuredImage}
                      href={fields.slug}
                    >
                      {frontmatter.excerpt}...
                    </AggregatorPin4>
                  ))}
                </PinBlockGroup>
                <ReadMore className={styles.readMore}>
                  <TransitionLink className={styles.readMore} to={slug}>
                    See more
                  </TransitionLink>
                </ReadMore>
                <Divider thickness={4} />
              </div>
            )
          )
        })}
      </AggregatorTemplate>
    </MDXProvider>
  )
}

CatalysingPageTemplate.propTypes = propTypes

const CatalysingPage = ({ data }) => {
  const {
    page: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <CatalysingPageTemplate data={data} />
    </LayoutFullWidth>
  )
}

CatalysingPage.propTypes = propTypes

export default CatalysingPage

export const pageQuery = graphql`
  query CatalysingPageTemplate($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
      }
    }
    categories: allMdx(
      filter: { fields: { contentType: { eq: "projectCategories" } } }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    projects: allMdx(
      filter: { fields: { contentType: { eq: "projects" } } }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  height
                  src
                  srcSet
                  width
                }
              }
            }
            categories
          }
        }
      }
    }
  }
`
